import { memo, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Case as CaseType } from '~types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import DisableCase from './DisableCase';
import playAudio from '~frontend/utils/playAudio';
import Currency from '../../Currency';
import FormatDate from './FormatDate';
import { Price } from '~ui/index';
import ImgCasePlaceholder from '../../../scss/images/placeholderNew.svg?react';
import ImgCasePlaceholderImg from '../../../scss/images/placeholder.png';
import { useCalceOfPossibleFreeOpenPrice } from '~frontend/components/play/hooks';
import { useConfig } from '~components/hooks';
import './caseBlock.scss';
import dayjs from 'dayjs';
import { useInterval } from 'react-use';

interface CaseBlockProps {
  getCase: CaseType;
  itemName?: string;
  isBattlePassCoins?: boolean;
}

export const CaseBlock: FunctionComponent<CaseBlockProps> = memo(
  ({ getCase, itemName, isBattlePassCoins = false }: CaseBlockProps) => {
    const { t } = useTranslation();
    const getConfig = useConfig();
    const [timeLeft, setTime] = useState(null);

    useInterval(
      () => {
        setTime(dayjs(getCase?.getDateEnd).diff(dayjs(), 'milliseconds'));
      },
      timeLeft === 0 || getCase?.type !== 2 ? null : 1000,
    );

    const isDisable = useMemo(() => {
      if (getCase?.type === 2 && timeLeft < 0) {
        return true;
      } else if (getCase?.type == 1 && getCase?.getLeft === 0) {
        return true;
      }

      return getCase?.isDisable;
    }, [getCase?.getLeft, getCase?.isDisable, getCase?.type, timeLeft]);

    const [price1] = useCalceOfPossibleFreeOpenPrice(
      getCase,
      getCase?.userOpenForFree,
      1,
    );

    const [price, , freeCountOpen, battlePassCoins] =
      useCalceOfPossibleFreeOpenPrice(getCase, getCase?.userOpenForFree, 1);

    const getChance =
      getCase?.getOdd?.itemsPercents[
        _.replace(itemName, /.*(StatTrak™)./gm, '')
      ];

    const [screenWidth, setScreenWidth] = useState<number>(0);
    const [isHover, setIsHover] = useState<boolean>(false);

    useEffect(() => {
      // Set initial screenWidth
      setScreenWidth(window.innerWidth);
    }, [getCase]);

    const handleMouseEnter = (event: React.MouseEvent) => {
      setIsHover(true);
      playAudio(event, '4.wav', 0.5);
    };

    const handleMouseLeave = () => {
      setIsHover(false);
    };

    const getImageSrc = () => {
      if (screenWidth > 768 || isEmpty(getCase.resizedImages)) {
        return {
          image: getCase?.getImage,
          backgroundImage: getCase?.getBackgroundImage,
        };
      } else if (screenWidth > 480) {
        return {
          image: getCase?.resizedImages.middle_768.images,
          backgroundImage: getCase?.resizedImages.middle_768.background,
        };
      } else if (screenWidth > 360) {
        return {
          image: getCase?.resizedImages.small_480.images,
          backgroundImage: getCase?.resizedImages.small_480.background,
        };
      } else {
        return {
          image: getCase?.resizedImages.small_360.images,
          backgroundImage: getCase?.resizedImages.small_360.background,
        };
      }
    };

    const { image, backgroundImage } = getImageSrc();
    if (getCase?.id === 221) {
      console.log(`case ${getCase.getName} is disabled ${getCase.isDisable}`);
    }

    return (
      <Link
        to={getCase?.getUrl}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`case ${getCase?.isDisable ? '1234' : ''}`}
        style={
          isDisable || getCase?.isDisable
            ? { pointerEvents: 'none', opacity: 0.48 }
            : {}
        }
      >
        {getCase?.getLeft > 0 && getCase?.type === 1 && (
          <div className="case_percent">
            {' '}
            {getCase?.getLeft} / {getCase?.getLimit}{' '}
          </div>
        )}

        {getCase?.getDateEnd && getCase?.type === 2 && (
          <div className="case_percent">
            <FormatDate getCase={getCase} />
          </div>
        )}

        <LazyLoadImage
          alt={getCase?.getName}
          src={image}
          placeholder={<ImgCasePlaceholder />}
          delayTime={0}
          width={256}
          height={256}
          //placeholderSrc={ImgCasePlaceholderImg}
        />
        <span className="case_name">{getCase?.getName}</span>
        <span className="case_cost">
          {price === 0 ? (
            'FREE'
          ) : (
            <>
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={price} />
                </>
              ) : (
                <>
                  <Price sum={price} />
                  <Currency />
                </>
              )}
            </>
          )}
        </span>
      </Link>

      // <DisableCase getCase={getCase} classNames={`case-item`}>
      //   <Link
      //     to={getCase?.getUrl}
      //     onMouseEnter={handleMouseEnter}
      //     onMouseLeave={handleMouseLeave}
      //     className="case"
      //   >
      //     <div className="case-item-tooltipes">
      //       {getCase?.isHit && <div className="case-hit" />}
      //       {getCase?.isNew && <div className="case-latest" />}
      //       {price === 0 && <div className="case-free" />}
      //     </div>

      //     {getCase?.type === 1 && getCase?.getLeft > 0 && (
      //       <div className="case-item-nums">
      //         <div className="case-item-nums-wrap">
      //           <span
      //             key={'case-item-nums-0'}
      //             className="fas fa-bolt icon icon-case-name"
      //           />
      //           <span key={'case-item-nums-1'} className="red">
      //             {getCase?.getLeft}
      //           </span>
      //           <span key={'case-item-nums-2'}> / </span>
      //           <span key={'case-item-nums-3'}>{getCase?.getLimit}</span>
      //         </div>
      //       </div>
      //     )}
      //     {getCase?.getDateEnd && <FormatDate getCase={getCase} />}
      //     <div className="case-item-img">
      //       <div className="btn-open-case">{t('Open')}</div>

      //       {isHover ? (
      //         <LazyLoadImage
      //           alt={getCase?.getName}
      //           className="hover"
      //           src={image}
      //           placeholder={<ImgCasePlaceholder />}
      //           delayTime={0}
      //           //  placeholderSrc={ImgCasePlaceholderImg}
      //         />
      //       ) : (
      //         <LazyLoadImage
      //           alt={getCase?.getName}
      //           className="not-hover"
      //           src={backgroundImage}
      //           placeholder={<ImgCasePlaceholder />}
      //           delayTime={0}
      //           //  placeholderSrc={ImgCasePlaceholderImg}
      //         />
      //       )}
      //     </div>
      //     {getChance && <div className="drop-chance">{getChance}%</div>}
      //     <div className="case-item-name">
      //       <span>
      //         <div className="text-cut">{getCase?.getName}</div>
      //         {getCase?.needToOpenForFree > 0 && (
      //           <i className="case-before-free">
      //             ({getCase?.userOpenForFree}/{getCase?.needToOpenForFree})
      //           </i>
      //         )}
      //       </span>
      //     </div>
      //     {getCase?.priceOld > 0 && (
      //       // SCRUM-151 hide old price and hide bonuses
      //       <></>
      //       // <span className="open-case old-sale">
      //       //   <>
      //       //     {getConfig.isEU ? (
      //       //       <>
      //       //         <Currency />
      //       //         <Price sum={getCase?.priceOld} />
      //       //       </>
      //       //     ) : (
      //       //       <>
      //       //         <Price sum={getCase?.priceOld} />
      //       //         <Currency />
      //       //       </>
      //       //     )}
      //       //   </>
      //       // </span>
      //     )}
      //     <div className="case-cost">
      //       {price === 0 ? (
      //         'FREE'
      //       ) : (
      //         <>
      //           {getConfig.isEU ? (
      //             <>
      //               <Currency />
      //               <Price sum={price} />
      //             </>
      //           ) : (
      //             <>
      //               <Price sum={price} />
      //               <Currency />
      //             </>
      //           )}
      //         </>
      //       )}
      //       {freeCountOpen > 0 && (
      //         <div className="btn-free-case-count">{freeCountOpen}</div>
      //       )}
      //     </div>
      //     {battlePassCoins > 0 && (
      //       <div className="case-cost-battle-pass">
      //         +<Price sum={battlePassCoins} />
      //         <svg
      //           width="16"
      //           height="16"
      //           viewBox="0 0 16 16"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <g opacity="0.5">
      //             <path
      //               fillRule="evenodd"
      //               clipRule="evenodd"
      //               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM9 13.917C11.8377 13.441 14 10.973 14 8C14 5.027 11.8377 2.55904 9 2.08296V5H11L12 6H7L6 7V9L7 10H12L11 11H9V13.917ZM7 13.917V11H6L4 9V7L6 5H7V2.08296C4.16229 2.55904 2 5.027 2 8C2 10.973 4.16229 13.441 7 13.917Z"
      //               fill="white"
      //             ></path>
      //           </g>
      //         </svg>
      //       </div>
      //     )}
      //   </Link>
      // </DisableCase>
    );
  },
);
